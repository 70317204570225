<template>
  <div>
    <!-- title -->
    <div class="pageTitle">富強鑫展示畫面即時資訊</div>

    <!-- alert -->
    <div class="alert_Info">
      <ul>
        <li>
          <div
            v-b-tooltip.hover.bottom
            title="系統正常營運"
            class="d-flex align-items-center"
          >
            <i
              class="fas fa-check-circle mr-1"
              style="font-size: 20px; color: var(--success)"
            ></i>
            <div>
              {{ $t("realTime.status.systemNormal") }} ({{
                alarmAmount["0"] ?? 0
              }})
            </div>
          </div>
        </li>

        <li>
          <div
            v-b-tooltip.hover.bottom
            title="依通訊裝置異常告警數值定義(不含 Inv 斷訊)"
            class="d-flex align-items-center"
          >
            <i
              class="fas fa-radiation-alt mr-1"
              style="font-size: 20px; color: var(--yellow)"
            ></i>
            <div>
              {{ $t("realTime.status.partialSystemError") }} ({{
                alarmAmount["3"] ?? 0
              }})
            </div>
          </div>
        </li>

        <li>
          <div
            v-b-tooltip.hover.bottom
            title="案場斷網 30 分鐘內"
            class="d-flex align-items-center"
          >
            <img
              class="d-block mr-1"
              src="../assets/hourglass.svg"
              alt="系統短暫斷線圖示"
              width="20"
            />
            <div>
              {{ $t("realTime.status.deviceDisconnectedShort") }} ({{
                alarmAmount["7"] ?? 0
              }})
            </div>
          </div>
        </li>

        <li>
          <div
            v-b-tooltip.hover.bottom
            title="停電後 Buffer 回補、斷網後計算回補、特殊計算回補、  三日內無資料"
            class="d-flex align-items-center"
          >
            <img
              class="d-block mr-1"
              src="../assets/file-error.svg"
              alt="資料缺漏回補圖示"
              width="20"
            />
            <div>
              {{ $t("realTime.status.backfill") }} ({{ alarmAmount["5"] ?? 0 }})
            </div>
          </div>
        </li>

        <li>
          <div
            v-b-tooltip.hover.bottom
            title="Inv 斷訊(null)、Inv 未發電"
            class="d-flex align-items-center"
          >
            <img
              class="d-block mr-1"
              src="../assets/electrical.svg"
              alt="發電數據異常圖示"
              width="20"
            />
            <div>
              {{ $t("realTime.status.noPowerData") }} ({{
                alarmAmount["2"] ?? 0
              }})
            </div>
          </div>
        </li>

        <li>
          <div
            v-b-tooltip.hover.bottom
            title="案場斷網大於 30 分鐘"
            class="d-flex align-items-center"
          >
            <img
              class="d-block mr-1"
              src="../assets/smart-plug.svg"
              alt="系統未連線圖示"
              width="20"
            />
            <div>
              {{ $t("realTime.status.deviceDisconnected") }} ({{
                alarmAmount["6"] ?? 0
              }})
            </div>
          </div>
        </li>

        <li>
          <div
            v-b-tooltip.hover.bottom.html="{
              title:
                '1. Inv 有發電但效率不佳(RA)<br>2. Mppt 不發電或發電不佳<br>3. 保證PR / 保證發電量未達標',
            }"
            class="d-flex align-items-center"
          >
            <img
              class="mr-1"
              src="../assets/poor-power.svg"
              alt="發電效率不佳圖示"
              width="20"
            />
            <div>
              {{ $t("realTime.status.poorPower") }} ({{
                alarmAmount["9"] ?? 0
              }})
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div
      v-for="(i, idx) in fuqiangxinData"
      :key="i.key"
      class="newRealTimeStyle"
      :id="i.factoryId"
    >
      <table>
        <div class="rwdFactoryName">{{ i.displaySiteName }}</div>

        <tr>
          <th rowspan="2" style="font-size: 22px; width: 12%">
            {{ i.displaySiteName }}
          </th>
          <th>設置地點</th>
          <th>設置容量(kWp)</th>
          <th>日照強度<span class="text-nowrap">(W/㎡)</span></th>
          <th>模組溫度(°C)</th>
          <!-- <th>直流功率(kW)</th>
          <th>交流功率(kW)</th> -->
          <th>今日發電量(kWh)</th>

          <th style="width: 10%">
            本月累計發電量(kWh) &nbsp;<i
              class="fas fa-exclamation-circle"
              style="opacity: 0.9"
              :id="'popover-accMon' + idx"
            ></i>
          </th>
          <b-popover
            :target="'popover-accMon' + idx"
            triggers="hover"
            placement="right"
          >
            <template #title>更新頻率 1 小時 / 次</template>
            累計數據每小時 05 分時，計算後更新<br />(
            取得最新數據，請重新整理頁面 )
          </b-popover>

          <th style="width: 10%">
            平均每日每 kW 發電量 &nbsp;<i
              class="fas fa-exclamation-circle"
              style="opacity: 0.9"
              :id="'popover-peakWatt' + idx"
            ></i>
          </th>
          <b-popover
            :target="'popover-peakWatt' + idx"
            triggers="hover"
            placement="right"
          >
            <template #title>更新頻率 1 小時 / 次</template>
            累計數據每小時 05 分時，計算後更新<br />(
            取得最新數據，請重新整理頁面 )
          </b-popover>

          <th>INV效率(%)</th>
          <th>今日PR(%)</th>
          <th>案場狀態</th>
        </tr>

        <tr>
          <td data-title="設置地點" class="set_site">
            {{ fuqiangxinSetSite[idx] }}
            <!-- <span v-if="idx === 3"><br />+ 捷盟西</span> -->
          </td>

          <transition name="slide-fade">
            <td
              data-title="設置容量(kWp)"
              v-if="i.capacityAll && i.displayAlertLevel !== 6"
            >
              {{ i.capacityAll.toFixed(2) }}
            </td>
            <td
              data-title="設置容量(kWp)"
              v-else-if="i.displayAlertLevel === 6"
            >
              -
            </td>
            <td data-title="設置容量(kWp)" v-else>null</td>
          </transition>

          <transition name="slide-fade">
            <td
              :key="i.dataTimeFromNow"
              data-title="日照強度(W/㎡)"
              v-if="
                i.IRR &&
                i.IRR?.length &&
                !i.IRR.includes(null) &&
                i.displayAlertLevel !== 6
              "
            >
              {{ dataHandle(handleIRRArray(i.IRR) * 1000, 0) }}
            </td>
            <td
              data-title="日照強度(W/㎡)"
              v-else-if="i.displayAlertLevel === 6"
            >
              -
            </td>
            <td data-title="日照強度(W/㎡)" v-else>null</td>
          </transition>

          <transition name="slide-fade">
            <td
              :key="i.dataTimeFromNow"
              data-title="模組溫度(°C)"
              v-if="i.PVTemp && i.displayAlertLevel !== 6"
            >
              {{ i.PVTemp }}
            </td>
            <td data-title="模組溫度(°C)" v-else-if="i.displayAlertLevel === 6">
              -
            </td>
            <td data-title="模組溫度(°C)" v-else>null</td>
          </transition>

          <transition name="slide-fade">
            <td
              :key="i.dataTimeFromNow"
              data-title="今日發電量(kWh)"
              v-if="i.E_today && i.E_today?.length && i.displayAlertLevel !== 6"
            >
              {{ sumValue(i.E_today) }}
            </td>
            <td
              data-title="今日發電量(kWh)"
              v-else-if="i.displayAlertLevel === 6"
            >
              -
            </td>
            <td data-title="今日發電量(kWh)" v-else>null</td>
          </transition>

          <transition name="slide-fade">
            <td
              :key="i.dataTimeFromNow"
              data-title="本月累計發電量(kWh)"
              v-if="
                factoryAccMonData?.[idx]?.acpAll && i.displayAlertLevel !== 6
              "
            >
              {{ factoryAccMonData[idx].acpAll.toFixed(2) }}
            </td>
            <td
              data-title="本月累計發電量(kWh)"
              v-else-if="i.displayAlertLevel === 6"
            >
              -
            </td>
            <td data-title="本月累計發電量(kWh)" v-else>
              {{ factoryAccMonData?.[idx]?.acpAll ?? "null" }}
            </td>
          </transition>

          <transition name="slide-fade">
            <td
              :key="i.dataTimeFromNow"
              data-title="平均每日每 kW 發電量"
              v-if="
                factoryDayAvgPeakWattData?.[idx]?.avgPeakWatt &&
                i.displayAlertLevel !== 6
              "
            >
              {{ factoryDayAvgPeakWattData?.[idx]?.avgPeakWatt?.toFixed(2) }}
            </td>
            <td
              data-title="平均每日每 kW 發電量"
              v-else-if="i.displayAlertLevel === 6"
            >
              -
            </td>
            <td data-title="平均每日每 kW 發電量" v-else>
              {{ factoryDayAvgPeakWattData?.[idx]?.avgPeakWatt ?? "null" }}
            </td>
          </transition>

          <transition name="slide-fade">
            <td
              :key="i.dataTimeFromNow"
              data-title="INV效率(%)"
              v-if="
                i.acp &&
                i.dcp &&
                i.acp?.length &&
                i.dcp?.length &&
                i.displayAlertLevel !== 6
              "
            >
              {{
                dataHandle((handleInvSum(i.acp) / handleInvSum(i.dcp)) * 100, 0)
              }}
            </td>
            <td data-title="INV效率(%)" v-else-if="i.displayAlertLevel === 6">
              -
            </td>
            <td data-title="INV效率(%)" v-else>null</td>
          </transition>

          <transition name="slide-fade">
            <td
              data-title="今日PR(%)"
              v-if="
                typeof i.PrRaMon !== 'string' &&
                i.PrRaMon?.ACP &&
                i.PrRaMon?.PR &&
                i.capacity &&
                i.capacity?.length &&
                i.displayAlertLevel !== 6
              "
            >
              {{ dataHandle(i.PrRaMon.PR * 100, 1) }}
            </td>
            <td data-title="今日PR(%)" v-else-if="i.displayAlertLevel === 6">
              -
            </td>
            <td data-title="今日PR(%)" v-else>null</td>
          </transition>

          <transition name="slide-fade">
            <td
              data-title="案場狀態"
              v-b-tooltip.right
              :title="displayAlertLevelToText(i.displayAlertLevel)"
            >
              <i
                v-if="i.displayAlertLevel === 0"
                class="fas fa-check-circle"
                style="color: var(--success)"
              ></i>
              <img
                v-else-if="
                  i.displayAlertLevel === 1 || i.displayAlertLevel === 2
                "
                src="../assets/electrical.svg"
                alt="發電數據異常圖示"
                width="20"
                class="d-block mx-auto"
              />
              <i
                v-else-if="i.displayAlertLevel === 3"
                class="fas fa-radiation-alt"
                style="color: var(--yellow)"
              ></i>
              <img
                v-else-if="i.displayAlertLevel === 5"
                src="../assets/file-error.svg"
                alt="資料缺漏回補圖示"
                width="20"
                class="d-block mx-auto"
              />
              <img
                v-else-if="i.displayAlertLevel === 6"
                src="../assets/smart-plug.svg"
                alt="系統未連線圖示"
                width="20"
                class="d-block mx-auto"
              />
              <img
                v-else-if="i.displayAlertLevel === 7"
                src="../assets/hourglass.svg"
                alt="系統短暫斷線圖示"
                width="20"
                class="d-block mx-auto"
              />
              <i
                v-else-if="i.displayAlertLevel === 4"
                class="fas fa-search-minus"
                style="color: var(--purple)"
              ></i>
              <img
                v-else-if="i.displayAlertLevel === 9"
                src="../assets/poor-power.svg"
                alt="發電效率不佳圖示"
                width="20"
                class="d-block mx-auto"
              />
            </td>
          </transition>
        </tr>
      </table>
    </div>

    <div class="newRealTimeStyle">
      <table>
        <div class="rwdFactoryName">合計</div>
        <tr>
          <th rowspan="2" style="font-size: 22px; width: 12%">合計</th>
          <th>總設置容量(kWp)</th>
          <th>今日發電量合計(kW)</th>
          <th>
            本月累積發電量合計(kW) &nbsp;<i
              class="fas fa-exclamation-circle"
              style="opacity: 0.9"
              id="popover-siteAcpToday"
            ></i>
          </th>
          <b-popover
            target="popover-siteAcpToday"
            triggers="hover"
            placement="right"
          >
            <template #title>更新頻率 1 小時 / 次</template>
            累計數據每小時 05 分時，計算後更新<br />(
            取得最新數據，請重新整理頁面 )
          </b-popover>
        </tr>

        <tr>
          <td data-title="總設置容量(kWp)">
            {{ siteCapacity }}
          </td>

          <transition name="slide-fade">
            <td data-title="今日發電量合計(kW)">
              {{ siteAcpToday }}
            </td>
          </transition>

          <transition name="slide-fade">
            <td data-title="本月累積發電量合計(kW)">
              {{ siteAcpSum.toFixed(2) }}
            </td>
          </transition>
        </tr>
      </table>
    </div>

    <div class="newRealTimeStyle">
      <table>
        <div class="rwdFactoryName">售電金額合計</div>
        <tr>
          <th rowspan="2" style="font-size: 22px; width: 12%">售電金額合計</th>
          <th>今日售電金額合計</th>
          <th>
            本月累積售電金額合計 &nbsp;<i
              class="fas fa-exclamation-circle"
              style="opacity: 0.9"
              id="popover-sitePriceSum"
              ><b-popover
                target="popover-sitePriceSum"
                triggers="hover"
                placement="right"
              >
                <template #title>更新頻率 1 小時 / 次</template>
                累計數據每小時 05 分時，計算後更新<br />(
                取得最新數據，請重新整理頁面 )
              </b-popover></i
            >
          </th>
        </tr>

        <tr>
          <transition name="slide-fade">
            <td data-title="今日售電金額合計">
              {{ sitePowerPriceToday }}
            </td>
          </transition>

          <transition name="slide-fade">
            <td data-title="本月累積售電金額合計">
              {{ numberThousandsComma(sitePriceSum.toFixed()) }}
            </td>
          </transition>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getFuqiangxinApi } from '../api'
import { BPopover } from 'bootstrap-vue'
import { dbDataHandle } from '../store/function/common/index'
import i18n from '../lang/lang'
export default {
  name: 'FuQiangXinDemo',
  components: { BPopover },
  data () {
    return {
      fuqiangxinList: [
        'FCS1期(東)',
        '富強鑫西側整改',
        'FCS2期',
        'FCS3期',
        'FCS4期',
        'FCS5期',
        'FCS6期'
      ],
      fuqiangxinSetSite: [
        '東側南面',
        '西側南面',
        '東側慕品 A',
        '東側 11 - 12 棟南面 + 捷盟西',
        '捷盟東',
        '西側北面 + 慕品 M',
        '東側北面'
      ],
      factoryAccMonData: [], // 本月累計發電量
      factoryDayAvgPeakWattData: [], // 平均每日每kw發電量(峰瓩值)
      siteAcpSum: 0, // 本月累積發電量合計
      sitePriceSum: 0 // 本月累積售電金額合計
    }
  },
  computed: {
    ...mapState(['userDetail', 'factoryData', 'factoryInfo']),
    fuqiangxinInfo () {
      return this.factoryInfo
        .filter(this.isInFuqiangxinList)
        .sort(
          (a, b) =>
            this.fuqiangxinList.indexOf(a.displaySiteName) -
            this.fuqiangxinList.indexOf(b.displaySiteName)
        )
    },
    fuqiangxinData () {
      return this.factoryData
        .filter(this.isInFuqiangxinList)
        .sort(
          (a, b) =>
            this.fuqiangxinList.indexOf(a.displaySiteName) -
            this.fuqiangxinList.indexOf(b.displaySiteName)
        )
    },
    alarmAmount () {
      // 告警數量 { "0": 5, "2": 2 } (key: displayAlertLevel)
      return this.factoryData
        .filter(this.isInFuqiangxinList)
        .reduce((accumulator, curr) => {
          if (!accumulator[curr.displayAlertLevel]) {
            accumulator[curr.displayAlertLevel] = 0
          }
          accumulator[curr.displayAlertLevel] += 1
          return accumulator
        }, {})
    },
    siteCapacity () {
      // 為與舊雲端平台四捨五入後數字一致，先四捨五入再加總
      const total = this.fuqiangxinData.reduce(
        (accumulator, curr) =>
          Math.round((accumulator + curr.capacityAll) * 100) / 100,
        0
      )
      return total
    },
    siteAcpToday () {
      const total = this.fuqiangxinData.reduce(
        (accumulator, curr) => accumulator + this.sumValue(curr.E_today),
        0
      )
      return total.toFixed(2)
    },
    sitePowerPriceToday () {
      const total = this.fuqiangxinData.reduce((accumulator, curr, currIdx) => {
        // 無 unitPrice(string): Number('-') -> NaN
        const unitPrice =
          Number(this.fuqiangxinInfo[currIdx].informations.unitPrice) || 0

        return accumulator + this.sumValue(curr.E_today) * unitPrice
      }, 0)
      return this.numberThousandsComma(total.toFixed())
    }
  },
  methods: {
    ...mapActions(['asyncSpecificLastDoc', 'asyncInfo', 'getAllAlarm']),
    isInFuqiangxinList (obj) {
      return this.fuqiangxinList.includes(obj.displaySiteName)
    },
    numberThousandsComma (num) {
      let result = ''

      for (var i = num?.length - 1, j = 0; i >= 0; i--, j++) {
        if (j !== 0 && j % 3 === 0) {
          result = ',' + result
        }
        result = num[i] + result
      }
      return result
    },
    displayAlertLevelToText (displayAlertLevel) {
      if (displayAlertLevel === 0) return '系統正常營運'
      if (displayAlertLevel === 2) return '發電數據異常'
      if (displayAlertLevel === 3) return '部分通訊異常'
      if (displayAlertLevel === 5) return '資料缺漏回補'
      if (displayAlertLevel === 6) return '系統未連線'
      if (displayAlertLevel === 7) return '系統短暫斷線'
      if (displayAlertLevel === 9) return '發電效率不佳'
    },

    sumValue (v) {
      if (!v?.length) return 0
      const value = this.dataHandle(v.reduce((a, b) => a + b) * 1)
      if (value <= 0) return 0
      return value
    },
    handleInvSum (v) {
      if (v && v?.length) return this.dataHandle(v.reduce((a, b) => a + b))
      return 0
    },
    dataHandle (val, index) {
      return dbDataHandle(val, index)
    },
    handleIRRArray (irr) {
      const type = Object.prototype.toString
        .call(irr)
        .slice(8, -1)
        .toLowerCase()
      if (irr === undefined || irr === null) return
      if (type && type === 'number') return irr
      if (type && type === 'array') {
        if (irr.includes(null)) return null
        return irr && irr?.length
          ? parseFloat(irr.reduce((a, b) => a + b).toPrecision(12)) /
              irr?.length
          : 0
      }
      if (type && type === 'object') return irr.avg ? irr.avg : 0
    },
    handleInHundred (num) {
      if (num >= 100) {
        return 100
      } else {
        return num
      }
    }
  },
  async created () {
    // 能進到這頁 代表 admin 以上 或是 富強鑫權限
    // 可看案場 ALL 或是 fuqiangxinList 所有案場

    const hasFuqiangxinAllSitesPermission =
      this.userDetail.factoriesList.includes('ALL') ||
      this.fuqiangxinList.every((siteName) =>
        this.userDetail.factoriesList.includes(siteName)
      )
    if (!hasFuqiangxinAllSitesPermission) {
      this.$swal('權限不足，請洽系統管理員')
      this.$router.push({
        path: `/${i18n.locale}/realtime`
      })
    }

    if (!this.factoryInfo.length) {
      await this.asyncInfo()
      await this.getAllAlarm()
    }

    const hasLoadedAllFuqiangxinData = this.fuqiangxinList.every((siteName) =>
      this.factoryData.map((obj) => obj.displaySiteName).includes(siteName)
    )

    if (!hasLoadedAllFuqiangxinData) {
      const queryFuqiangxinList = this.factoryInfo.filter(
        this.isInFuqiangxinList
      )

      // 要共用 factoryData 才能得到即時資訊(websocket 是更新到 factoryData)
      await this.asyncSpecificLastDoc(queryFuqiangxinList)
    }

    // 取得 富強鑫 累加資料
    const { data } = await getFuqiangxinApi()

    const sortByFuqiangxinList = (a, b) =>
      this.fuqiangxinList.indexOf(a.factoryName) -
      this.fuqiangxinList.indexOf(b.factoryName)

    this.factoryAccMonData = data.data.accMon.sort(sortByFuqiangxinList)
    this.factoryDayAvgPeakWattData =
      data.data.dayAvgPeakWatt.sort(sortByFuqiangxinList)
    this.siteAcpSum = data.data.allSiteSum.siteAcpSum
    this.sitePriceSum = data.data.allSiteSum.sitePriceSum
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/RealTime.scss";

.alert_Info {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-top: 27px;
  margin-bottom: 18px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    li {
      cursor: default;
      list-style: none;
      font-weight: 700;
      font-size: 17px;
      &:not(:last-child) {
        margin-right: 15px;
      }
      min-width: auto;
    }
  }
}
.newRealTimeStyle {
  margin: 12px 15px;
  table {
    cursor: default;
    box-sizing: border-box;
    width: 100%;
    table-layout: fixed;
    &:hover {
      box-shadow: 1px 1px 5px var(--gray-dark);
    }
  }

  table tr {
    box-sizing: border-box;
    background-color: #cfeaff;
  }
  table th {
    box-sizing: border-box;
    text-shadow: 2px 2px #316fae;
    background: var(--acmeTableTitle);
    color: white;
    font-size: 14.5px;
    text-align: center;
    @media only screen and (min-width: 1680px) {
      padding: 7px 0px;
    }
  }

  table td {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 700;
    border-right: solid 1px rgb(135, 204, 111, 0.5);
    text-align: center;

    &.set_site {
      font-size: 17px;
    }
  }

  table td:nth-last-child(1) {
    border: none;
  }
}

.rwdFactoryName {
  display: none;
}

.peekDisplayNameBtn {
  cursor: help;
  font-size: 14px;
  color: #dfefff;
}

@media only screen and (max-width: 768px) {
  .rwdFactoryName {
    border-radius: 5px 5px 0px 0px;
    padding: 5px;
    font-size: 22px;
    display: block;
    background: var(--acmeTableTitle);
    text-align: center;
    color: aliceblue;
    text-shadow: 2px 2px #316fae;
    font-weight: 700;
  }
  td:nth-child(even) {
    background: #f8f8f8;
  }
  th {
    display: none;
  }
  td {
    border: none !important;
    text-shadow: 2px 2px #dee2e6;
    text-align: start !important;
    display: block;
    padding: 0.3rem 0.2rem;
    font-size: 18px;
  }
  td:before {
    content: attr(data-title);
    display: inline-block;
    width: 200px;
    min-width: 200px;
    margin-right: 8rem;
    margin-left: 0.5rem;
    font-size: 18px;
  }
}
@media only screen and (max-width: 520px) {
  td:before {
    margin-right: 2rem;
  }
}

// modal 內的要用 :deep 否則樣式無法傳遞到內層(parent to child component)
:deep(.alertTable) {
  .table {
    table-layout: fixed;
    font-weight: 700;
    .thead-light {
      th {
        background-color: rgba(204, 207, 196, 0.178);
        color: rgb(17, 11, 11);
        font-weight: 700;
        font-size: 14px;
      }
    }
    th:nth-child(2) {
      width: 80%;
    }
    th {
      text-align: center;
    }
    td {
      word-break: break-all;
      text-align: center;
      padding: 10px;
    }
    td:nth-child(2) {
      text-align: left;
    }

    // alert modal level table background colors
    @each $key, $value in $level-color-array {
      .table-#{$key} {
        td:nth-child(1) {
          background-color: rgba($value, 20%);
          border-color: rgba($value, 30%);
        }
      }
    }

    // red bg adjust darken, can distinguish orange
    .table-red {
      td:nth-child(1) {
        background-color: rgba($c-red, 25%);
      }
    }
  }

  .table-hover {
    tr {
      &:hover {
        td:nth-child(1) {
          filter: brightness(95%);
        }
      }
    }
  }
}
</style>
